import React, { FC, useState } from "react";
import { CpButton, CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { useCss, k } from "kremling";
import useUserPrefsQuery from "src/common/queries/use-user-prefs-query.hook";
import { FilterViewType, SelectionType } from "./contact-list.component";
import ExportContactListModal from "./export-contact-list/export-contact-list-modal.component";
import { useHistory, useLocation } from "react-router-dom";
import { SubView } from "./use-contact-list-subview";

type KebabMenuProps = {
  filterViews: FilterViewType[];
  filterViewOverrides: Record<string, any>;
  selection: SelectionType;
  currentFilters: Record<string, any>;
  currentSortData: any[];
  currentFilterViewId: string;
  globalSearch: string;
  totalContactsCount: number;
  schema: Record<string, any>;
  subView: SubView | null;
};

export const KebabMenu: FC<KebabMenuProps> = ({
  filterViews,
  filterViewOverrides,
  selection,
  currentFilters,
  currentSortData,
  currentFilterViewId,
  globalSearch,
  totalContactsCount,
  schema,
  subView,
}) => {
  const { userPrefs, updateUserPrefs } = useUserPrefsQuery();
  const scope = useCss(css);
  const history = useHistory();
  const location = useLocation();
  const [showExportModal, setShowExportModal] = useState(false);
  const hasImportAccess = useHasAccess("clients_import");

  function renderDisplayAs(clientNameDisplayBy: any, label: string) {
    const displayPref = userPrefs?.clientNameDisplayBy;
    return (
      <button
        {...scope}
        className="displayAsButton"
        onClick={() => {
          updateUserPrefs({ clientNameDisplayBy });
        }}
      >
        <div className="cp-flex-center cp-gap-16">
          {label}
          {(displayPref === clientNameDisplayBy || (!displayPref && clientNameDisplayBy === "first")) && (
            <CpIcon name="checkmark-large" fill="var(--cp-color-button-icon-active-text)" />
          )}
        </div>
      </button>
    );
  }

  return (
    <>
      <CpDropdown
        contentWidth="auto"
        renderTrigger={({ toggle }: any) => <CpButton icon="misc-kabob" aria-label="More options" onClick={toggle} />}
        renderContent={() => (
          <div className="cp-select-list" {...scope}>
            <button
              onClick={() => {
                setShowExportModal(true);
              }}
            >
              Export filtered list
            </button>
            {subView?.id === "archived" ? (
              <button onClick={() => history.push("all")}>All contacts</button>
            ) : (
              <button onClick={() => history.push("archived")}>Archived contacts</button>
            )}
            {hasImportAccess && (
              <button
                onClick={() => {
                  history.push(`/crm/import?referrer=${encodeURIComponent(location.pathname)}`);
                }}
              >
                Import contacts
              </button>
            )}
            <div className={"dropdown-divider"}></div>
            {renderDisplayAs("first", "First Name, Last Name")}
            {renderDisplayAs("last", "Last Name, First Name")}
          </div>
        )}
      />
      <ExportContactListModal
        onClose={() => {
          setShowExportModal(false);
        }}
        show={showExportModal}
        filterViews={filterViews}
        filterViewOverrides={filterViewOverrides}
        selection={selection}
        currentFilters={currentFilters}
        currentSortData={currentSortData}
        currentFilterViewId={currentFilterViewId}
        globalSearch={globalSearch}
        totalContactsCount={totalContactsCount}
        schema={schema}
      />
    </>
  );
};

const css = k`
.dropdown-divider {
  height: 0.1rem;
  background-color: var(--cp-color-app-border);
  margin: 0.4rem 0rem 0.4rem 0rem;
}

.displayAsButton {
  /* Keeping the min height to 40px to account for the "checked" icon that changes the option height */
  min-height: 4rem;
}

`;
