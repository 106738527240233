import React, { Suspense, lazy, useMemo, useState, Fragment } from "react";
import { CpIcon, CpLoader, CpTooltip } from "canopy-styleguide!sofe";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import ContactModal from "../contact-modal/contact-modal.component";
import { RemovePortalAccessModal } from "./remove-portal-access-modal.component";
import { TAssignedClient } from "src/common/types";
import { ArchiveContactModal } from "./archive-contact-modal";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { SubView } from "./use-contact-list-subview";
import { deleteContact, resendPortalInvite, unarchiveContact } from "./context-menu-actions";
import { pluralCount } from "src/common/string.helpers";
import useContactQuery from "src/common/queries/use-contact-query.hook";

const ComposeEmail = lazy(() =>
  SystemJS.import("communications-ui!sofe").then((commsUI: any) => commsUI.getComposeEmailOverlay())
);

export function ContextMenu({ contact, subView }: { contact: any; subView: SubView | null }) {
  useCssModules(css);
  const [showModal, setShowModal] = useState<string>("");
  const primaryClientsCount = contact?.clients?.filter((c: TAssignedClient) => c.is_primary).length;
  const { contact: fetchedContact } = useContactQuery(
    { contactId: contact?.id },
    {
      staleTime: Infinity,
      enabled: !!contact?.id,
    }
  );
  const qboClient = fetchedContact?.clients?.find(
    (c: TAssignedClient) => c.canopy_contact_id_for_third_party === contact.id
  );

  const actions = useMemo(() => {
    return [
      {
        id: "edit",
        name: "Edit contact",
        icon: "crud-pencil",
        permissions: ["clients_create_edit"],
        onClick: () => setShowModal("edit_contact"),
      },

      ...(contact?.clients?.some((c: TAssignedClient) => !!c.user_invited_at)
        ? [
            {
              id: "resend_portal_invite",
              name: "Resend portal invite",
              icon: "person-resend",
              permissions: ["clients_manage_client_portals"],
              onClick: () => resendPortalInvite(contact),
            },
          ]
        : []),
      ...(contact?.clients?.some((c: TAssignedClient) => !!c.user_invited_at)
        ? [
            {
              id: "remove_portal_access",
              name: "Remove portal access",
              icon: "person-remove",
              permissions: ["clients_manage_client_portals"],
              onClick: () => setShowModal("remove_portal_access"),
            },
          ]
        : []),
      {
        id: "email",
        name: "Send email",
        icon: "communication-envelope",
        disabled: !contact?.primary_email,
        tooltip: contact?.primary_email
          ? undefined
          : `${contact?.name} is missing an email address. Add an email address to their contact record before drafting an email.`,
        onClick: () => setShowModal("compose_email"),
      },
      {
        id: "archive",
        name: "Archive contact",
        icon: "crud-archive",
        permissions: ["contacts_archive"],
        onClick: () => setShowModal("archive"),
        disabled: primaryClientsCount > 0 || !!qboClient,
        tooltip:
          primaryClientsCount > 0 ? (
            `${contact.name} is assigned as a primary contact to ${pluralCount(
              primaryClientsCount,
              "client record"
            )}. A primary contact cannot be archived.`
          ) : qboClient ? (
            <div>
              To archive this contact, you must first edit the QBO sync contact selection on the{" "}
              <a
                style={{ all: "revert", color: "var(--cp-color-app-primary-dark-text)" }}
                href={`/#/client/${qboClient.id}`}
                onClick={close}
              >
                {qboClient.name}
              </a>{" "}
              client record.
            </div>
          ) : undefined,
      },
      {
        id: "unarchive",
        name: "Unarchive contact",
        icon: "af-undo",
        permissions: ["contacts_archive"],
        onClick: () => unarchiveContact(contact),
      },
      {
        id: "delete",
        name: "Delete contact",
        icon: "crud-trash-large",
        permissions: ["contacts_delete"],
        onClick: () => deleteContact(contact),
      },
    ];
  }, [contact, primaryClientsCount, qboClient]);

  const defaultActions = useMemo(
    () => actions.map((action) => action.id).filter((id) => !["unarchive", "delete"].includes(id)),
    [actions]
  );

  const [user] = useWithUserAndTenant();

  return (
    <>
      <div className="cp-select-list">
        {actions
          .filter((action) => {
            const allowedActions = subView?.contextMenuActions || defaultActions;
            if (allowedActions && !allowedActions.includes(action.id)) {
              return false;
            }
            return !action.permissions || hasAccess(user, true)(action.permissions);
          })
          .map((action) => {
            const Wrapper = action.tooltip ? CpTooltip : Fragment;
            const wrapperProps = action.tooltip
              ? {
                  text: action.tooltip,
                  position: "bottom",
                  interactive: action.tooltip && !!qboClient,
                }
              : {};
            return (
              <Wrapper key={action.id} {...wrapperProps}>
                <div>
                  <button
                    onClick={action.onClick}
                    disabled={action.disabled}
                    className={action.disabled ? s.disabled : ""}
                  >
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name={action.icon}
                      aria-label={action.name}
                      fill={action.disabled ? "var(--cp-color-app-disabled-text)" : "var(--cp-color-app-icon)"}
                    />
                    {action.name}
                  </button>
                </div>
              </Wrapper>
            );
          })}
      </div>
      {showModal === "edit_contact" && (
        <ContactModal onAfterClose={() => setShowModal("")} contactId={contact.id} mode="edit" />
      )}
      {showModal === "remove_portal_access" && (
        <RemovePortalAccessModal contact={contact} onAfterClose={() => setShowModal("")} />
      )}
      {showModal === "compose_email" && (
        <Suspense fallback={<CpLoader />}>
          <ComposeEmail
            to={contact.primary_email}
            close={() => {
              setShowModal("");
            }}
          />
        </Suspense>
      )}
      {showModal === "archive" && <ArchiveContactModal contact={contact} onAfterClose={() => setShowModal("")} />}
    </>
  );
}

const { css, s } = cssModules`
  .disabled {
    color: var(--cp-color-app-disabled-text) !important;
  }
`;
