import { featureEnabled } from "feature-toggles!sofe";
export const columnWidthsPx = {
  sm: 110,
  md: 200,
  lg: 300,
  clientName: 250,
};

const defaultVisibleColumns = {
  __ACTIVE_CLIENTS: [
    "full_name",
    "display_name",
    "first_name",
    "last_name",
    "middle_name",
    "ein",
    "ssn",
    "phone",
    "client_owner",
    "team_members",
    "contact_person",
    "spouse_name",
    "business_type",
    "client_type",
    "tags",
    "locality",
    "region",
    "qbo",
  ],
  __BUSINESSES: [
    "full_name",
    "display_name",
    "ein",
    "phone",
    "client_owner",
    "team_members",
    "contact_person",
    "business_type",
    "client_type",
    "tags",
    "locality",
    "region",
    "qbo",
  ],
  __INDIVIDUALS: [
    "full_name",
    "display_name",
    "first_name",
    "last_name",
    "middle_name",
    "ssn",
    "phone",
    "client_owner",
    "team_members",
    "contact_person",
    "spouse_name",
    "client_type",
    "tags",
    "locality",
    "region",
    "qbo",
  ],
};

const defaultHierarchyVisibleColumns = {
  __ACTIVE_CLIENTS: [
    "name",
    "client_type",
    "contacts",
    "cp_user_invited",
    "ein",
    "client_owner",
    "team_members",
    "business_type",
    "tags",
    "qbo",
    "client_groups",
  ],
  __INDIVIDUALS: [
    "name",
    "client_type",
    "contacts",
    "cp_user_invited",
    "client_owner",
    "team_members",
    "tags",
    "qbo",
    "client_groups",
  ],
};

export function getDefaultVisibleColumns(filterViewId, customFieldIds, ft_crm) {
  const defaultId = "__ACTIVE_CLIENTS";
  const columnMap = ft_crm ? defaultHierarchyVisibleColumns : defaultVisibleColumns;
  const defaultCols = columnMap[filterViewId] || columnMap[defaultId];
  return [...defaultCols, ...(customFieldIds?.map((id) => "custom_field_" + id) || [])];
}

export function getDefaultColumnOrder(ft_crm) {
  return ft_crm && featureEnabled("toggle_new_client_list_attributes")
    ? [
        "name",
        "is_active",
        "contacts",
        "client_groups",
        "cp_user_invited",
        "client_since",
        "created_at",
        "client_type",
        "primary_contact_name",
        "primary_contact_phone",
        "primary_contact_email",
        "primary_contact_street_address",
        "primary_contact_locality",
        "primary_contact_region",
        "primary_contact_postal_code",
        "primary_contact_country",
        "primary_contact_employer",
        "primary_contact_occupation",
        "primary_contact_birthday",
        "primary_contact_tin",
        "spouse_name",
        "business_name",
        "business_type",
        "contact_person",
        "date_established",
        "ein",
        "industry",
        "business_phone",
        "business_email",
        "business_address",
        "business_locality",
        "business_region",
        "business_postal_code",
        "business_country",
        "external_id",
        "filing_status",
        "referred_by",
        "client_source",
        "teams",
        "team_members",
        "client_owes",
        "client_owner",
        "qbo",
        "tags",
      ]
    : [];
}

// Returns a copy of colsToSort sorted by the columnOrder
export function sortColumns(colsToSort, columnOrder) {
  return [...colsToSort].sort((a, b) => {
    let indexA = columnOrder.indexOf(a);
    let indexB = columnOrder.indexOf(b);
    // If index cannot be found then push these columns to the end of the list
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;

    return indexA - indexB;
  });
}
